<template>
    <!--  历史数据  -->
    <!-- 添加或修改参数配置对话框 -->
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="查询时间" prop="createStarTime">
                <el-date-picker size="mini"
                                v-model="timeRange"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="data">
            <el-table-column label="日期" prop="createTime">
                <template slot-scope="scope">
                    {{scope.row.createTime.substr(0,11)}}
                </template>
            </el-table-column>
            <el-table-column label="流水">
                <template slot-scope="scope">
                    {{scope.row.amountNew + scope.row.amountPersonal + scope.row.amountRoutine +
                    scope.row.amountStock}}
                </template>
            </el-table-column>
            <el-table-column label="时长">
                <template slot-scope="scope">
                    {{scope.row.durationNew + scope.row.durationPersonal + scope.row.durationRoutine +
                    scope.row.durationStock}}
                </template>
            </el-table-column>
            <el-table-column label="拨打量">
                <template slot-scope="scope">
                    {{scope.row.daillCountNew + scope.row.daillCountPersonal + scope.row.daillCountRountine +
                    scope.row.daillCountStock}}
                </template>
            </el-table-column>
            <el-table-column label="领取首咨数" prop="consultCount">
                <template slot-scope="scope">
                    {{scope.row.consultCount}}
                </template>
            </el-table-column>
            <!--                <el-table-column label="分配机会数" prop="id"/>-->
            <!--                <el-table-column label="添加微信数" prop="id"/>-->
            <el-table-column label="机会接通量" prop="id">
                <template slot-scope="scope">
                    {{scope.row.connectionStock + scope.row.connectionRountine + scope.row.connectionPersonal +
                    scope.row.connectionNew}}
                </template>
            </el-table-column>
        </el-table>

        <pagination
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.limit"
                @pagination="handleQuery"
        />
    </div>
</template>

<script>
    import {statisticalPage} from "../utils/api"

    export default {
        name: "historyRecord",
        props: {
            open: {
                default: false
            },
            title: {
                default: "查询列表"
            }
        },
        data() {
            return {
                data: [],
                total: 0,
                queryParams: {
                    page: 1,
                    limit: 10
                },
                timeRange: []
            }
        },
        methods: {
            resetQuery() {
                this.resetForm("form");
                this.queryParams = {
                    page: 1,
                    limit: 10
                }
                this.timeRange = []
            },
            handleQuery() {
                if (this.timeRange[0]) {
                    let startTime = this.timeRange[0] + " 00:00:00";
                    this.queryParams['startTime'] = startTime;
                }
                if (this.timeRange[1]) {
                    let endTime = this.timeRange[1] + " 59:59:59";
                    this.queryParams['endTime'] = endTime;
                }
                statisticalPage(this.queryParams).then(res => {
                    this.data = res.data.data;
                    this.total = res.data.total;
                });
            }
        }
    }
</script>

<style scoped>

</style>
